import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import 'leaflet/dist/leaflet.css'
import './fns/telegram-web-app'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)
